<template>
  <div
    class="mt-8 h-[1.3px] bg-grey w-4/4 flex justify-center items-center mx-auto"
  ></div>
</template>

<script>
/* eslint-disable */
export default {
  name: "JaguarDivider",
};
</script>

<style></style>
